
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Input, Row, Select, Switch } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeFromMenu, setMenuData } from '../../redux/slices/menu';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchBanners } from '../../redux/slices/banner';
import shopService from '../../services/shop';
import bannerService from '../../services/banner';
import { useTranslation } from 'react-i18next';

const BannerAdd = () => {
    const { t } = useTranslation();
    const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [image, setImage] = useState(activeMenu.data?.image || null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [shops, setShops] = useState([]);
    const [isTextType, setIsTextType] = useState(false); // State to manage type

    useEffect(() => {
        return () => {
            const data = form.getFieldsValue(true);
            dispatch(setMenuData({ activeMenu, data }));
        };
    }, []);

    const onFinish = (values) => {
        const body = {
            url: values.url,
            position: values.position,
            text: isTextType ? values.text : null,
            type: isTextType ? values.type : values.type,
            shop_id: values.shop_id,
            'images[0]': image?.name,
        };
        setLoadingBtn(true);
        const nextUrl = 'banners';
        bannerService
            .create(body)
            .then(() => {
                toast.success(t('successfully.created'));
                dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
                navigate(`/${nextUrl}`);
                dispatch(fetchBanners());
            })
            .finally(() => setLoadingBtn(false));
    };

    function fetchShops() {
        shopService.get().then(({ data }) => setShops(data));
    }

    useEffect(() => {
        fetchShops();
    }, []);

    const positions = [
        { id: 1, name: "banner1" },
        { id: 2, name: "banner2" },
        { id: 3, name: "banner3" },
        { id: 4, name: "banner4" },
        { id: 5, name: "text" }
    ];

    return (
        <Card title={t('add.banner')} className='h-100'>
            <Form
                name='banner-add'
                layout='vertical'
                onFinish={onFinish}
                form={form}
                initialValues={{ active: true, ...activeMenu.data }}
                className='d-flex flex-column h-100'
            >
                <Row gutter={12}>
                    <Col span={24}>
                        <Form.Item label={t('type')}>
                            <Switch
                                checked={isTextType}
                                onChange={setIsTextType}
                                checkedChildren={t('text')}
                                unCheckedChildren={t('banner')}
                            />
                        </Form.Item>
                    </Col>

                    {isTextType ? (
                        <>
                            <Col span={12}>
                                <Form.Item
                                    label={t('text')}
                                    name={'text'}
                                    rules={[{ required: true, message: t('required') }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t('banner.position')}
                                    name={'position'}
                                    rules={[{ required: true, message: t('required') }]}
                                >
                                    <Select>
                                        {positions.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {t(item.name)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t('url') + ' ' + '(  https://www.example.com )'}
                                    name={'url'}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t('shop')}
                                    name={'shop_id'}
                                    rules={[{ required: true, message: t('required') }]}
                                >
                                    <Select>
                                        {shops.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.translation?.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col span={24}>
                                <Form.Item
                                    label={t('image')}
                                    name='image'
                                    rules={[{ required: true, message: t('required') }]}
                                >
                                    <ImageUploadSingle
                                        type='banners'
                                        image={image}
                                        setImage={setImage}
                                        form={form}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t('shop')}
                                    name={'shop_id'}
                                    rules={[{ required: true, message: t('required') }]}
                                >
                                    <Select>
                                        {shops.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.translation?.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={t('banner.position')} name={'position'}>
                                    <Select>
                                        {positions.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {t(item.name)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label={t('url') + ' ' + '(  https://www.example.com )'}
                                    name={'url'}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
                <div className='flex-grow-1 d-flex flex-column justify-content-end'>
                    <div className='pb-5'>
                        <Button type='primary' htmlType='submit' loading={loadingBtn}>
                            {t('submit')}
                        </Button>
                    </div>
                </div>
            </Form>
        </Card>
    );
};

export default BannerAdd;

