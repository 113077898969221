import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
} from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../redux/slices/menu';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchBanners } from '../../redux/slices/banner';
import shopService from '../../services/shop';
import bannerService from '../../services/banner';
import { IMG_URL } from '../../configs/app-global';
import { useTranslation } from 'react-i18next';

const BannerEdit = () => {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageExists, setImageExists] = useState(false); // State for image existence
  const shop_id = Form.useWatch('shop_id', form);

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  const createImage = (name) => {
    return {
      name,
      url: IMG_URL + name,
    };
  };

  const getBanner = (alias) => {
    setLoading(true);
    bannerService
        .getById(alias)
        .then((res) => {
          const banner = res.data;
          form.setFieldsValue({
            ...banner,
            image: createImage(banner.img),
          });
          setImage(createImage(banner.img));
          setImageExists(!!banner.img); // Check if the image exists
        })
        .finally(() => {
          setLoading(false);
          dispatch(disableRefetch(activeMenu));
        });
  };

  const onFinish = (values) => {
    const body = {
      url: values.url,
      shop_id: values.shop_id,
      position: values.position,
      type: values.type,
      text: values.text,
      'images[0]': image?.name,
    };
    setLoadingBtn(true);
    const nextUrl = 'banners';
    bannerService
        .update(id, body)
        .then(() => {
          toast.success(t('successfully.updated'));
          dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
          navigate(`/${nextUrl}`);
          dispatch(fetchBanners());
        })
        .finally(() => setLoadingBtn(false));
  };

  const fetchShops = () => {
    shopService.get().then(({ data }) => setShops(data));
  };

  useEffect(() => {
    if (activeMenu.refetch) {
      getBanner(id);
    }
    fetchShops();
  }, [activeMenu.refetch]);

  const positions = [
    { id: 1, name: "banner1" },
    { id: 2, name: "banner2" },
    { id: 3, name: "banner3" },
    { id: 4, name: "banner4" },
    { id: 5, name: "text" }
  ];

  return (
      <Card title={t('edit.banner')} className='h-100'>
        {!loading ? (
            <Form
                name='banner-edit'
                layout='vertical'
                onFinish={onFinish}
                form={form}
                initialValues={{ active: true, ...activeMenu.data }}
                className='d-flex flex-column h-100'
            >
              <Row gutter={12}>
                {imageExists && ( // Conditionally render the image input
                    <Col span={24}>
                      <Form.Item
                          label={t('image')}
                          name='image'
                      >
                        <ImageUploadSingle
                            type='banners'
                            image={image}
                            setImage={setImage}
                            form={form}
                        />
                      </Form.Item>
                    </Col>
                )}
                <Col span={12}>
                  <Form.Item label={t('url')} name={'url'}>
                    <Input />
                  </Form.Item>
                </Col>

                {/* Conditionally render the text input if image does not exist */}
                {!imageExists && (
                    <Col span={12}>
                      <Form.Item label={t('text')} name={'text'}>
                        <Input />
                      </Form.Item>
                    </Col>
                )}

                <Col span={12}>
                  <Form.Item label={t('banner.position')} name={'position'}>
                    <Select>
                      {positions.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {t(item.name)}
                          </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                      label={t('shop')}
                      name={'shop_id'}
                      rules={[{ required: true, message: t('required') }]}
                  >
                    <Select>
                      {shops.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.translation?.title}
                          </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <div className='flex-grow-1 d-flex flex-column justify-content-end'>
                <div className='pb-5'>
                  <Button type='primary' htmlType='submit' loading={loadingBtn}>
                    {t('submit')}
                  </Button>
                </div>
              </div>
            </Form>
        ) : (
            <div className='d-flex justify-content-center align-items-center'>
              <Spin size='large' className='py-5' />
            </div>
        )}
      </Card>
  );
};

export default BannerEdit;
